import * as React from 'react';
import {
    DateInput,
    Edit,
    NullableBooleanInput,
    TextInput,
    PasswordInput,
    SimpleForm,
    useTranslate,
    NumberInput,
    BooleanInput,
    ReferenceInput
} from 'react-admin';

export const EntityfieldEdit = () => (
    <Edit>
        <SimpleForm>
            <TextInput source="label" />
            <TextInput source="description" />
            <TextInput source="calculatedFormulae" />
            <TextInput source="defaultValue" />
            <TextInput source="defaultValueFormulae" />
            <TextInput source="mask" />
            <TextInput source="lengthByte" />
            <TextInput source="lengthScale" />
            <TextInput source="lengthPrecision" />
            <NumberInput source="lengthString" />
            <TextInput source="fieldType" />
            <TextInput source="dataType" />
            <BooleanInput source="allowHTMLContent" />
            <BooleanInput source="allowInCalculated" />
            <TextInput source="allowUpdate" />
            <BooleanInput source="allowFiltering" />
            <BooleanInput source="allowGrouping" />
            <BooleanInput source="allowSorting" />
            <BooleanInput source="isCustom" />
            <BooleanInput source="isActive" />
            <BooleanInput source="isHidden" />
            <BooleanInput source="isMasked" />
            <BooleanInput source="isUnique" />
            <BooleanInput source="isRequired" />
            <BooleanInput source="isAudited" />
            <TextInput source="pickListValues" />
            <ReferenceInput source="entityId" reference="Entity" recordRepresentation="Name" queryOptions={{ meta: { path: 'metadata' } }} />
            <TextInput source="id" />
            <TextInput source="name" />
            <TextInput source="subscriptionKey" />
            <DateInput source="createdDateTime" />
            <DateInput source="updatedDateTime" />
            <TextInput source="_ts" />
        </SimpleForm>
    </Edit>
);
export default EntityfieldEdit;

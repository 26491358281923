import SetupIcon from '@mui/icons-material/Settings';
import EntityList from './EntityList';
import EntityCreate from './EntityCreate';
import EntityEdit from './EntityEdit';

const resource = {
    list: EntityList,
    create: EntityCreate,
    edit: EntityEdit,
    icon: SetupIcon,
    recordRepresentation: (record: any) =>
        `${record.first_name} ${record.last_name}`,
};

export default resource;

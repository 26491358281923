import * as React from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from '@mui/material';
import { Link, useTranslate, useGetMany, useRecordContext } from 'react-admin';


const Basket = () => {
    const record = useRecordContext();
    const translate = useTranslate();

    // const productIds = record ? record.fields.map(field => field.product_id) : [];

    // const { isPending, data: products } = useGetMany<Product>(
    //     'products',
    //     { ids: productIds },
    //     { enabled: !!record }
    // );
    // const productsById = products
    //     ? products.reduce((acc, product) => {
    //           acc[product.id] = product;
    //           return acc;
    //       }, {} as any)
    //     : {};

    // if (isPending || !record || !products) return null;

    return record && record.fields && record.fields.length >0 ?
     (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>
                        Name
                    </TableCell>
                    <TableCell>
                        Label
                    </TableCell>
                    <TableCell>
                        FieldType
                    </TableCell>
                    <TableCell>
                        Description
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {record.fields.map((field: any) => (
                    <TableRow key={field.entityId}>
                        <TableCell>
                            <Link to={`/EntityField/${field.id}`}>
                                {field.name}
                            </Link>
                        </TableCell>
                        <TableCell>{field.label}</TableCell>
                        <TableCell>{field.fieldType}</TableCell>
                        <TableCell>{field.description}</TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    ) : null;
};

export default Basket;

import * as React from 'react';
import { SolarLayout, SolarLayoutProps, ContainerLayout, LayoutProps, Header } from '@react-admin/ra-navigation';
import { useQueryClient } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import {
    useStore,
    useRedirect,
    useDataProvider,
    useRefresh,LocalesMenuButton, LoadingIndicator
} from 'react-admin';

import { Logout, UserMenu, useUserMenu } from 'react-admin';
import { MenuList, MenuItem, ListItemIcon, ListItemText } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';

import { TourProvider } from '@react-admin/ra-tour';
import AppBar from './AppBar';
import { Menu } from './Menu';
import CustomBreadcrumb from './Breadcrumb';
import { CssBaseline, Container } from '@mui/material';

const RA_TOUR_PREFERENCE_KEY = '@react-admin/ra-tour';

const myAppBar = <Header color="primary" position="sticky" />;

const SettingUserMenu = () => (
    <MenuItem >
        <SettingsIcon />
    </MenuItem>
);

const toolbar = (
    <>
        <LoadingIndicator />
        <SettingUserMenu />
    </>
);

const Layout = (props: LayoutProps) => {
    const [tourPreferences, setTourPreferences] = useStore(
        RA_TOUR_PREFERENCE_KEY
    );
    const redirect = useRedirect();
    const dataProvider = useDataProvider();
    const queryClient = useQueryClient();
    const refresh = useRefresh();

    return (
        
            <>
                <ReactQueryDevtools initialIsOpen={false} />                
                {/* <SolarLayout {...props} menu={Menu} appBar={AppBar}>
                    <CustomBreadcrumb />
                    {props.children}
                </SolarLayout> */}
                <ContainerLayout {...props}
                    appBar={myAppBar} toolbar={toolbar} menu={<Menu />}>
                    {/* <CustomBreadcrumb /> */}
                    {props.children}
                </ContainerLayout>
            </>

    );
};

export default Layout;

import * as React from 'react';
import { useState, useEffect, ReactElement } from 'react';
import {
    useAuthProvider,
    useGetIdentity,
    useSavedQueries,
    useTranslate,
    useGetList
} from 'react-admin';
import { useSubscribeToRecordList } from '@react-admin/ra-realtime';
import { Avatar, Typography, styled, Badge } from '@mui/material';
import {
    SolarMenu,
    useAppLocationMatcher,
    useResourceAppLocation,
    HorizontalMenu,
} from '@react-admin/ra-navigation';
import {
    EventNote as EventNoteIcon,
    CheckCircleOutline as CheckCircleOutlineIcon,
    AvTimer as AvTimerIcon,
    Block as BlockIcon,
    Search as SearchIcon,
    Settings as SetupIcon,
} from '@mui/icons-material';
import querystring from 'query-string';
import { endOfYesterday } from 'date-fns';

import { SearchSubMenu } from './SearchSubMenu';
import entities from '../entities';
import { ProfileSubMenu } from './ProfileSubMenu';
import { SetupSubMenu } from './SetupSubMenu';
import SettingsIcon from '@mui/icons-material/Settings';

export const newCustomerFilter = querystring.stringify({
    filter: JSON.stringify({
        last_seen_gte: endOfYesterday().toISOString(),
    }),
});

export const visitorsFilter = querystring.stringify({
    filter: JSON.stringify({ nb_orders_lte: 0 }),
});

export const pendingReviewFilter = querystring.stringify({
    filter: JSON.stringify({ status: 'pending' }),
});

const useResourceChangeCounter = (
    resource: string,
    appLocation: string
): number => {
    const match = useAppLocationMatcher();
    const location = useResourceAppLocation();
    const [countEvent, setCountEvent] = useState(0);

    useSubscribeToRecordList(({ payload }) => {
        if (!payload || !payload.ids) {
            return;
        }

        let count = payload.ids.length;

        if (location && match(appLocation)) {
            const { record } = location && (location.values || {});
            if (!record || record.id == null) {
                return;
            }

            count = payload.ids.filter(id => id !== record.id).length;
        }

        if (count) {
            setCountEvent(previous => previous + count);
        }
    }, resource);

    useEffect(() => {
        if (match(appLocation)) {
            setCountEvent(0);
        }
    }, [match, appLocation]);

    return countEvent;
};

const StyledBadgeForText = styled(Badge)(({ theme }) => ({
    width: '100%',
    display: 'unset',
    badge: {
        top: 13,
        right: 13,
        border: `1px solid ${theme.palette.background.paper}`,
        padding: '0 4px',
        width: '100%',
    },
}));

export const Menu = () => {
    const { isPending, data: tabs } = useGetList(
        'Entity',
        { 
            meta: { path: 'metadata' },
            pagination: { page: 1, perPage: 100 },
            sort: { field: 'name', order: 'ASC' },
            filter: { 'allowListView' : true }
        }
    );
    return (
        <HorizontalMenu> 
            {tabs && tabs.map(entity => (
                <HorizontalMenu.Item
                    // icon={<entities.icon />}
                    key={entity.id}
                    name={entity.name}
                    to= {'/'+entity.name}
                    label={entity.label}
                    value={entity.name}
                />
            ))}
        </HorizontalMenu>
    )
};

const usePersistedQueriesMenu = (
    resource: string
): { label: string; to: string }[] => {
    const [savedQueries] = useSavedQueries(resource);
    const savedQueriesMenuItems = savedQueries.map(({ label, value }) => ({
        label,
        to: `/${resource}?${querystring.stringify({
            filter: JSON.stringify(value.filter),
            sort: value?.sort?.field,
            order: value?.sort?.order,
            page: 1,
            perPage: value.perPage,
            displayedFilters: value.displayedFilters,
        })}`,
    }));

    return savedQueriesMenuItems;
};

const CustomBottomToolbar = () => (
    <>
        {/* <SearchMenuItem /> */}
        <SolarMenu.LoadingIndicatorItem />
        <SetupMenuItem />
        <SolarMenuUserItem />
    </>
);

const SearchMenuItem = () => (
    <SolarMenu.Item
        icon={<SearchIcon />}
        label="pos.search"
        name="search"
        subMenu={<SearchSubMenu />}
        data-testid="search-button"
    />
);

const SetupMenuItem = () => (
    <SolarMenu.Item
        icon={<SetupIcon />}
        label="pos.search"
        name="setup"
        to='/setup'
        data-testid="setup-button"
        subMenu={<SetupSubMenu />}
    />
);

const SolarMenuUserItem = () => {
    const { isPending, identity } = useGetIdentity();
    const authProvider = useAuthProvider();

    if (isPending) return null;
    const avatarSx = { maxWidth: '1.4em', maxHeight: '1.4em' };
    return (
        <SolarMenu.Item
            icon={
                authProvider ? (
                    identity?.avatar ? (
                        <Avatar
                            src={identity.avatar}
                            alt={identity.fullName}
                            sx={avatarSx}
                        />
                    ) : (
                        <Avatar sx={avatarSx} />
                    )
                ) : (
                    <SettingsIcon />
                )
            }
            label="pos.profile"
            name="profile"
            subMenu={<ProfileSubMenu />}
            data-testid="profile-button"
        />
    );
};

import { stringify } from 'query-string';
import { fetchUtils, DataProvider } from 'ra-core';

/**
 * Maps react-admin queries to a simple REST API
 *
 * This REST dialect is similar to the one of FakeRest
 *
 * @see https://github.com/marmelab/FakeRest
 *
 * @example
 *
 * getList     => GET http://my.api.url/posts?sort=['title','ASC']&range=[0, 24]
 * getOne      => GET http://my.api.url/posts/123
 * getMany     => GET http://my.api.url/posts?filter={id:[123,456,789]}
 * update      => PUT http://my.api.url/posts/123
 * create      => POST http://my.api.url/posts
 * delete      => DELETE http://my.api.url/posts/123
 *
 */
export default (
    apiUrl: string,
    httpClient = fetchUtils.fetchJson,
    countHeader: string = 'Content-Range'
): DataProvider => ({
    getList: (resource, params) => {
        const { page, perPage } = params.pagination || { page: 1, perPage: 10 };
        const { field, order } = params.sort || { field: 'id', order: 'ASC' };

        const rangeStart = (page - 1) * perPage;
        const rangeEnd = page * perPage - 1;

        const filter = params.filter;
        const operators: { [key: string]: string } = { '_gte': '>=', '_lte': '<=', '_neq': '!=' };
        const filters = Object.keys(filter).map(key => {
            const operator = operators[key.slice(-4)];
            return operator
                ? { field: key.slice(0, -4), operator, value: filter[key] }
                : { field: key, operator: '=', value: filter[key] };
        });
        const filterString = Object.keys(filters).map((key) => {
            return filters[key].field + ' ' + filters[key].operator + ' \"' + filters[key].value + '\"';
        }).join(' AND ');
        const query = {
            //sort: JSON.stringify([field, order]),
            //range: JSON.stringify([rangeStart, rangeEnd]),
            //filter: JSON.stringify(params.filter),
            filter: filterString,
            orderBy: field + ' ' + order,
            pageNo: page,
            pageSize: perPage,
        };
        const path = params.meta?.path ?? (resource=='Entity'||resource=='EntityField') ? 'metadata': 'data';
        if(params.meta?.queryKey){
            query[params.meta.queryKey] = params.meta.queryValue;
        }
        const url = `${apiUrl}/${path}/${resource}?${stringify(query)}`;
        const options =
            countHeader === 'Content-Range'
                ? {
                      // Chrome doesn't return `Content-Range` header if no `Range` is provided in the request.
                      headers: new Headers({
                          Range: `${resource}=${rangeStart}-${rangeEnd}`,
                      }),
                      signal: params?.signal,
                  }
                : { signal: params?.signal };

        return httpClient(url, options).then(({ headers, json }) => {
            if(json.data){
                return {
                    data: json.data,
                    total: json.count,
                };
            }
            else if (!headers.has(countHeader)) {
                throw new Error(
                    `The ${countHeader} header is missing in the HTTP Response. The simple REST data provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare ${countHeader} in the Access-Control-Expose-Headers header?`
                );
            }
            return {
                data: json,
                total:
                    countHeader === 'Content-Range'
                        ? parseInt(
                              headers.get('content-range')!.split('/').pop() ||
                                  '',
                              10
                          )
                        : parseInt(headers.get(countHeader.toLowerCase())!),
            };
        });
    },

    getOne: (resource, params) => {
        const path = params.meta?.path ?? (resource=='Entity'||resource=='EntityField') ? 'metadata': 'data';
        const query = {};
        if(params.meta?.queryKey){
            query[params.meta.queryKey] = params.meta.queryValue;
        }
        const url = `${apiUrl}/${path}/${resource}/${params.id}?${stringify(query)}`;
        return httpClient(url, {
            signal: params?.signal,
        }).then(({ json }) => ({
            data: json,
        }));
    },

    getMany: (resource, params) => {
        const path = params.meta?.path ?? 'data';
        const query = {
            ids : params.ids,
        };
        const url = `${apiUrl}/${path}/${resource}?${stringify(query)}`;
        return httpClient(url, { signal: params?.signal }).then(({ json }) => ({
            data: json?.data,
        }));
    },

    getManyReference: (resource, params) => {
        const path = params.meta?.path ?? 'data';
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;

        const rangeStart = (page - 1) * perPage;
        const rangeEnd = page * perPage - 1;

        const query = {
            sort: JSON.stringify([field, order]),
            range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
            filter: JSON.stringify({
                ...params.filter,
                [params.target]: params.id,
            }),
        };
        const url = `${apiUrl}/${path}/${resource}?${stringify(query)}`;
        const options =
            countHeader === 'Content-Range'
                ? {
                      // Chrome doesn't return `Content-Range` header if no `Range` is provided in the request.
                      headers: new Headers({
                          Range: `${resource}=${rangeStart}-${rangeEnd}`,
                      }),
                      signal: params?.signal,
                  }
                : { signal: params?.signal };

        return httpClient(url, options).then(({ headers, json }) => {
            if (!headers.has(countHeader)) {
                throw new Error(
                    `The ${countHeader} header is missing in the HTTP Response. The simple REST data provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare ${countHeader} in the Access-Control-Expose-Headers header?`
                );
            }
            return {
                data: json,
                total:
                    countHeader === 'Content-Range'
                        ? parseInt(
                              headers.get('content-range')!.split('/').pop() ||
                                  '',
                              10
                          )
                        : parseInt(headers.get(countHeader.toLowerCase())!),
            };
        });
    },

    update: (resource, params) => {
        const path = params.meta?.path ?? (resource=='Entity'||resource=='EntityField') ? 'metadata': 'data';
        return httpClient(`${apiUrl}/${path}/${resource}/${params.id}`, {
            method: 'PATCH',
            body: JSON.stringify(params.data),
        }).then(({ json }) => ({ data: json }));
    },
    // simple-rest doesn't handle provide an updateMany route, so we fallback to calling update n times instead
    updateMany: (resource, params) => {
        const path = params.meta?.path ?? 'data';
        return Promise.all(
            params.ids.map(id =>
                httpClient(`${apiUrl}/${resource}/${id}`, {
                    method: 'PATCH',
                    body: JSON.stringify(params.data),
                })
            )
        ).then(responses => ({
            data: responses.map(({ json }) => json.id),
        }))
    },

    create: (resource, params) => {
        const path = params.meta?.path ?? (resource=='Entity'||resource=='EntityField') ? 'metadata': 'data';
        return httpClient(`${apiUrl}/${path}/${resource}`, {
            method: 'POST',
            body: JSON.stringify(params.data),
        }).then(({ json }) => ({ data: json }))
    },

    delete: (resource, params) => {
        const path = params.meta?.path ?? (resource=='Entity'||resource=='EntityField') ? 'metadata': 'data';
        return httpClient(`${apiUrl}/${path}/${resource}/${params.id}`, {
            method: 'DELETE',
            headers: new Headers({
                'Content-Type': 'text/plain',
            }),
        }).then(({ json }) => ({ data: json }))
    },

    // simple-rest doesn't handle filters on DELETE route, so we fallback to calling DELETE n times instead
    deleteMany: (resource, params) => {
        const path = params.meta?.path ?? 'data';
        return Promise.all(
            params.ids.map(id =>
                httpClient(`${apiUrl}/${path}/${resource}/${id}`, {
                    method: 'DELETE',
                    headers: new Headers({
                        'Content-Type': 'text/plain',
                    }),
                })
            )
        ).then(responses => ({
            data: responses.map(({ json }) => json.id),
        }))
    }
});





// const { isPending, data: tabs } = dp.dataProvider.getMany(
//     'Entity',
//     { 
//         ids : ['0f8dde18-bc50-ef11-991a-6045bdc615dc','0f8dde18-bc50-ef11-991a-6045bdc615dc'],
//         meta: { path: 'data' }
//     }
// );
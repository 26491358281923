import * as React from 'react';
import { Card, CardContent } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOnOutlined';
import MailIcon from '@mui/icons-material/MailOutline';
import LocalOfferIcon from '@mui/icons-material/LocalOfferOutlined';
import {
    TextField,
    FilterList,
    FilterListItem,
    FilterLiveSearch,
    SavedQueriesList,
} from 'react-admin';
import {
    endOfYesterday,
    startOfWeek,
    subWeeks,
    startOfMonth,
    subMonths,
} from 'date-fns';

// import segments from '../segments/data';

const Aside = () => (
    <Card
        sx={{
            display: {
                xs: 'none',
                md: 'block',
            },
            //order: -1,
            flex: '0 0 15em',
            ml: 2,
            mr: 2,
            mt: 5.3,
            alignSelf: 'flex-start',
        }}
    >

        <CardContent sx={{ pt: 1 }}>
            {/* <FilterLiveSearch /> */}
            <SavedQueriesList />
            {/* <FilterList
                label="Name"
                icon={<MailIcon />}
            >
                <FilterListItem
                    label="SaleOrder"
                    value={{ name : 'SaleOrder' }}
                />
                <FilterListItem
                    label="SaleOrderLine"
                    value={{ name : 'SaleOrderLine' }}
                />
            </FilterList> */}
            <FilterList
                label="Created Date"
                icon={<AccessTimeIcon />}
            >
                <FilterListItem
                    label="resources.customers.filters.today"
                    value={{
                        createdDateTime_gte: endOfYesterday().toISOString(),
                        createdDateTime_lte: undefined,
                    }}
                />
                <FilterListItem
                    label="resources.customers.filters.this_week"
                    value={{
                        createdDateTime_gte: startOfWeek(new Date()).toISOString(),
                        createdDateTime_lte: undefined,
                    }}
                />
                <FilterListItem
                    label="resources.customers.filters.last_week"
                    value={{
                        createdDateTime_gte: subWeeks(
                            startOfWeek(new Date()),
                            1
                        ).toISOString(),
                        createdDateTime_lte: startOfWeek(new Date()).toISOString(),
                    }}
                />
                <FilterListItem
                    label="resources.customers.filters.this_month"
                    value={{
                        createdDateTime_gte: startOfMonth(new Date()).toISOString(),
                        createdDateTime_lte: undefined,
                    }}
                />
                <FilterListItem
                    label="resources.customers.filters.last_month"
                    value={{
                        createdDateTime_gte: subMonths(
                            startOfMonth(new Date()),
                            1
                        ).toISOString(),
                        createdDateTime_lte: startOfMonth(new Date()).toISOString(),
                    }}
                />
                <FilterListItem
                    label="resources.customers.filters.earlier"
                    value={{
                        createdDateTime_gte: undefined,
                        createdDateTime_lte: subMonths(
                            startOfMonth(new Date()),
                            1
                        ).toISOString(),
                    }}
                />
            </FilterList>
            <FilterList
                label="Last Modified"
                icon={<AccessTimeIcon />}
            >
                <FilterListItem
                    label="resources.customers.filters.today"
                    value={{
                        updatedDateTime_gte: endOfYesterday().toISOString(),
                        updatedDateTime_lte: undefined,
                    }}
                />
                <FilterListItem
                    label="resources.customers.filters.this_week"
                    value={{
                        updatedDateTime_gte: startOfWeek(new Date()).toISOString(),
                        updatedDateTime_lte: undefined,
                    }}
                />
                <FilterListItem
                    label="resources.customers.filters.last_week"
                    value={{
                        updatedDateTime_gte: subWeeks(
                            startOfWeek(new Date()),
                            1
                        ).toISOString(),
                        updatedDateTime_lte: startOfWeek(new Date()).toISOString(),
                    }}
                />
                <FilterListItem
                    label="resources.customers.filters.this_month"
                    value={{
                        updatedDateTime_gte: startOfMonth(new Date()).toISOString(),
                        updatedDateTime_lte: undefined,
                    }}
                />
                <FilterListItem
                    label="resources.customers.filters.last_month"
                    value={{
                        updatedDateTime_gte: subMonths(
                            startOfMonth(new Date()),
                            1
                        ).toISOString(),
                        updatedDateTime_lte: startOfMonth(new Date()).toISOString(),
                    }}
                />
                <FilterListItem
                    label="resources.customers.filters.earlier"
                    value={{
                        updatedDateTime_gte: undefined,
                        updatedDateTime_lte: subMonths(
                            startOfMonth(new Date()),
                            1
                        ).toISOString(),
                    }}
                />
            </FilterList>
            <FilterList
                label="Is Custom"
                icon={<MailIcon />}
            >
                <FilterListItem
                    label="ra.boolean.true"
                    value={{ isCustom: true }}
                />
                <FilterListItem
                    label="ra.boolean.false"
                    value={{ isCustom: false }}
                />
            </FilterList>

            {/* <FilterList
                label="resources.customers.filters.group"
                icon={<LocalOfferIcon />}
            >
                {segments.map(segment => (
                    <FilterListItem
                        label={segment.name}
                        key={segment.id}
                        value={{ groups: segment.id }}
                    />
                ))}
            </FilterList> */}
        </CardContent>
    </Card>
);

export default Aside;

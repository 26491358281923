import * as React from 'react';
import {
    DateInput,
    Edit,
    NullableBooleanInput,
    TextInput,
    PasswordInput,
    SimpleForm,
    useTranslate,
    BooleanInput,
    useRecordContext
} from 'react-admin';
import { PredictiveTextInput } from '@react-admin/ra-ai';
import { Grid, Box, Typography } from '@mui/material';

// import Aside from './Aside';
import EntityField from './EntityField';
// import FullNameField from './FullNameField';
// import SegmentsInput from './SegmentsInput';
// import { validateForm } from './EntityCreate';

const Spacer = () => <Box mb={1}>&nbsp;</Box>;

const EntityEdit = () => {
    const translate = useTranslate();
    
    return (
        <Edit 
            queryOptions={{ meta: { path: 'metadata', queryKey: 'relations', queryValue: 'all' } }}>
            <SimpleForm >
                <div>
                    <Grid container width={{ xs: '100%', xl:1600 }} spacing={2}>
                        <Grid item xs={12} md={8}>
                            <Typography variant="h6" gutterBottom>
                                {translate(
                                    'resources.customers.fieldGroups.identity'
                                )}
                            </Typography>
                            <Box display={{ xs: 'block', sm: 'flex' }}>
                                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                                    <TextInput source="name" isRequired />
                                </Box>
                                <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                                    <TextInput source="label" isRequired />
                                </Box>
                                <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                                    <TextInput source="collectionLabel" isRequired />
                                </Box>
                                <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                                    <TextInput source="description" isRequired />
                                    <PredictiveTextInput source="bio" multiline />
                                </Box>
                            </Box>
                            
                            <Box mt="1em" />

                            <Typography variant="h6" gutterBottom>
                                Settings
                            </Typography>
                            <Box display={{ xs: 'block', sm: 'flex' }}>
                                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                                    <BooleanInput source='isCustom' />
                                </Box>
                                <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                                    <BooleanInput source='isHidden' />
                                </Box>
                                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                                    <BooleanInput source='isAudited' />
                                </Box>
                                <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                                    <BooleanInput source='allowCreate' />
                                </Box>
                            </Box>
                            <Box display={{ xs: 'block', sm: 'flex' }}>
                                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                                    <BooleanInput source='allowEdit' />
                                </Box>
                                <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                                    <BooleanInput source='allowDelete' />
                                </Box>
                                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                                    <BooleanInput source='allowMerge' />
                                </Box>
                                <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                                    <BooleanInput source='allowSearch' />
                                </Box>
                            </Box>
                            <Box display={{ xs: 'block', sm: 'flex' }}>
                                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                                    <BooleanInput source='allowRetreive' />
                                </Box>
                                <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                                    <BooleanInput source='allowQueries' />
                                </Box>
                                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                                    <BooleanInput source='allowListView' />
                                </Box>
                                <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                                    <BooleanInput source='allowFormEditView' />
                                </Box>
                            </Box>
                            <Box display={{ xs: 'block', sm: 'flex' }}>
                                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                                    <BooleanInput source='allowFormView' />
                                </Box>
                                <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                                    <BooleanInput source='activateNotes' />
                                </Box>
                                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                                    <BooleanInput source='activateActivities' />
                                </Box>
                                <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                                    <BooleanInput source='enableDuplicateDetection' />
                                </Box>
                            </Box>
                            <Box display={{ xs: 'block', sm: 'flex' }}>
                                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                                    <BooleanInput source='trackMRU' />
                                </Box>
                                
                            </Box>

                        </Grid>
                    </Grid>
                    <Spacer />
                    <Typography variant="h6" gutterBottom>
                        {translate('resources.orders.section.items')}
                    </Typography>
                    <div>
                        <EntityField />
                    </div>
                </div>
            </SimpleForm>
        </Edit>
    );
};

export default EntityEdit;

import * as React from 'react';
import { useEffect } from 'react';
import {
    CustomRoutes,
    mergeTranslations,
    Resource,
    useStore,
    StoreContextProvider,
    localStorageStore,
    useGetMany,
    useGetList,
    useDataProvider,
    ListGuesser,
    EditGuesser,
    ShowGuesser
} from 'react-admin';
import { Admin, buildI18nProvider } from '@react-admin/ra-enterprise';
// import { addEventsForMutations } from '@react-admin/ra-audit-log';
import CssBaseline from '@mui/material/CssBaseline';
import {
    raTreeLanguageEnglish,
    raTreeLanguageFrench,
} from '@react-admin/ra-tree';
import {
    raTourLanguageEnglish,
    raTourLanguageFrench,
} from '@react-admin/ra-tour';
import { Route } from 'react-router';


import dataProvider from './dataProvider';
import authProvider from './authProvider';
import englishMessages from './i18n/en';
import frenchMessages from './i18n/fr';
import { Login, Layout } from './layout';
import entities from './entities';
import EntityFieldEdit from './entities/EntityFieldEdit';

import metadata from './metadata';
import Configuration from './configuration/Configuration';
import { EventList } from './EventList';
import { ThemeName, themes } from './themes/themes';
import { c } from 'vite/dist/node/types.d-aGj9QkWt';

const messages = {
    en: mergeTranslations(
        englishMessages,
        raTreeLanguageEnglish,
        raTourLanguageEnglish
    ),
    fr: mergeTranslations(
        frenchMessages,
        raTreeLanguageFrench,
        raTourLanguageFrench
    ),
};

const i18nProvider = buildI18nProvider(messages, 'en', [
    { locale: 'en', name: 'English' },
    { locale: 'fr', name: 'Français' },
]);

const store = localStorageStore(undefined, 'EECommerce');

const staticResources = [
    <Resource name="Entity" {...metadata} />,
    <Resource name="EntityField" list={metadata.list} edit={EntityFieldEdit} show={ShowGuesser} />
];

const fetchResources = () =>
    dataProvider.getList(
        'Entity',
        { 
            meta: { path: 'metadata' },
            pagination: { page: 1, perPage: 100 },
            sort: { field: 'name', order: 'ASC' },
            filter: { 'allowListView' : true }
        }
    ).then(({ data }: { data: any }) => {
        staticResources.push(
            ...data.map((entity: any) => {
                return <Resource key={entity.id} name={entity.name} list={ListGuesser} edit={EditGuesser} show={ShowGuesser}/>
            })
        );
        return staticResources;
    })
    .catch((error: any) => {
        console.error(error);
        return staticResources;
    })


const App = () => {
    const [themeName] = useStore<ThemeName>('themeName', 'nano');
    const lightTheme = themes.find(theme => theme.name === themeName)?.light;
    const darkTheme = themes.find(theme => theme.name === themeName)?.dark;

    return (
        <Admin
            title=""
            store={store}
            dataProvider={dataProvider}
            authProvider={authProvider}
            loginPage={Login}
            layout={Layout}
            i18nProvider={i18nProvider}
            disableTelemetry
            lightTheme={lightTheme}
            darkTheme={darkTheme}
            catchAll={ListGuesser}
        >
            <CssBaseline />            
            {fetchResources}
        </Admin>
    );
};

const AppWrapper = () => (
    <StoreContextProvider value={store}>
        <App />
    </StoreContextProvider>
);

export default AppWrapper;

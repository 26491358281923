import * as React from 'react';
import {
    TextField,
    TextInput,
    BooleanField,
    CreateButton,
    DatagridConfigurable,
    DateField,
    DateInput,
    ExportButton,
    FilterButton,
    List,
    NullableBooleanInput,
    NumberField,
    SearchInput,
    SelectColumnsButton,
    TopToolbar,
} from 'react-admin';
import { useMediaQuery, Theme } from '@mui/material';

// import SegmentsField from './SegmentsField';
// import SegmentInput from './SegmentInput';
// import CustomerLinkField from './CustomerLinkField';
// import ColoredNumberField from './ColoredNumberField';
// import MobileGrid from './MobileGrid';
import EntityListAside from './EntityListAside';
import { ListViewButton } from './ListViewButton';
import { useListView } from './useListView';
// import { USDFormat } from '../formatUtils';

const entityFilters = [
    <SearchInput key="q" source="q" alwaysOn />,
    <TextInput key="label" source="label" />,
    <TextInput key="name" source="name" />,
    <DateInput key="createdDateTime" source="createdDateTime" />,
    <DateInput key="updatedDateTime" source="updatedDateTime" />,
    <NullableBooleanInput key="isCustom" source="isCustom" />,
    // <NullableBooleanInput
    //     key="has_newsletter"
    //     source="has_newsletter"
    //     defaultValue
    // />,
    // <SegmentInput key="groups" source="groups" />,
];

const VisitorListActions = () => {
    const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('md'));
    const [view] = useListView();
    const [showFilter, setShowFilter] = React.useState(false);

    // When resizing manually the window to go from large to small, the useMediaQuery used here
    // triggers its change before the one in the VisitorList. This leads to an error because the
    // FilterButton requires the filters prop to be set.
    React.useEffect(() => {
        // Needed to avoid react warnings
        let isMounted = true;
        setTimeout(() => {
            if (isMounted) setShowFilter(isSmall);
        }, 0);
        return () => {
            isMounted = false;
        };
    }, [isSmall]);

    return (
        <TopToolbar>
            {showFilter ? <FilterButton /> : null}
            <CreateButton />
            {view === 'table' ? <SelectColumnsButton /> : null}
            <ExportButton />
            <ListViewButton />
        </TopToolbar>
    );
};

const EntityList = () => {
    const isXsmall = useMediaQuery<Theme>(theme =>
        theme.breakpoints.down('sm')
    );
    const [view] = useListView();
    const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('md'));
    return (
        <List
            queryOptions={{ meta: { path: 'metadata' } }}
            filters={isSmall ? entityFilters : undefined}
            sort={{ field: 'label', order: 'ASC' }}
            perPage={25}
            aside={<EntityListAside />}
            actions={<VisitorListActions />}
            sx={{ marginTop: isSmall ? undefined : -4 }}
        >
            {(
                <DatagridConfigurable
                    rowClick="edit"
                    sx={{
                        '& .column-groups': {
                            md: { display: 'none' },
                            lg: { display: 'table-cell' },
                        },
                    }}
                    omit={['id']}
                >
                    {columns}
                </DatagridConfigurable>
            )}
        </List>
    );
};

const columns = [
    <TextField source="id" key="id" />,
    <TextField source="label" key="label" />,
    <TextField source="name" key="name" />,
    <TextField source="collectionLabel" key="collectionLabel" />,
    <TextField source="description" key="description" />,
    <BooleanField source="isCustom" key="isCustom" />,
    <BooleanField source="allowListView" key="alowListView" />,
    <DateField source="createdDateTime" key="createdDateTime" />,
    <DateField source="updatedDateTime" key="updatedDateTime" showTime />,
    // <SegmentsField source="groups" key="groups" />,
];

export default EntityList;
